import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  ArrowRightThickIcon,
  Body,
  breakpoints,
  Card as DKCard,
  column,
  horizontal,
  mixins,
  PlusLogo,
  SecondaryLink,
  SecondaryTwoLink,
  typographyStyles,
  vertical,
} from "design-kit"

const BaseCard = styled(DKCard)<{ alignedRight?: boolean }>`
  ${props =>
    props.alignedRight
      ? column({
          widthMobile: 4,
          widthTablet: 6,
          widthDesktop: 9,
          offsetDesktop: 3,
        })
      : column({
          widthMobile: 4,
          widthTablet: 6,
          widthDesktop: 9,
        })}
  z-index: 1;
  padding: ${vertical.s} ${horizontal.m};
  margin-bottom: ${vertical.m};

  ${breakpoints.tablet`
    display: flex;
    padding: ${vertical.m} ${horizontal.m};
  `}

  ${breakpoints.desktop`
    padding: ${vertical.l} ${horizontal.xl};
    margin-bottom: ${vertical.xl};
  `}
`

const Card: React.FunctionComponent<{ alignedRight?: boolean }> = ({
  alignedRight = false,
  children,
}) => {
  return (
    <li
      css={css`
        position: relative;
      `}
    >
      <BaseCard
        element="div"
        borderRadius="m"
        kind="semi-transparent"
        alignedRight={alignedRight}
      >
        {children}
      </BaseCard>
    </li>
  )
}

const Title = styled.h3`
  ${typographyStyles.headline1}
  margin-bottom: ${vertical.xs};

  ${breakpoints.tablet`
    margin-bottom: 0;
    padding-right: ${horizontal.m};
  `}

  ${breakpoints.desktop`
    padding-right: ${horizontal.xl};
  `}
`

const BodyWithMargin = styled(Body)`
  margin-bottom: ${vertical.s};
`

const brokerageCardBody = (
  <React.Fragment>
    <BodyWithMargin>
      Our experts will search the whole market – that’s thousands of mortgages
      from over 90 lenders – to get you the fastest, easiest mortgage or
      remortgage ever.
    </BodyWithMargin>
    <SecondaryLink
      text="Get started"
      href="/how-can-we-help"
      icon={{
        kind: "right",
        icon: <ArrowRightThickIcon color="currentColor" />,
      }}
      width={{
        mobile: "full-width",
        tablet: "content-width",
        desktop: "content-width",
      }}
    />
  </React.Fragment>
)
const plusCardBody = (
  <React.Fragment>
    <BodyWithMargin>
      We handle all your buying admin – your property survey, legal work,
      mortgage, everything. Buying a home should be a high point in your life,
      not a stressful one.
    </BodyWithMargin>
    <SecondaryTwoLink
      text="Read more"
      href="/home-buying"
      descriptiveText="More about our complete home-buying service"
      width={{
        mobile: "full-width",
        tablet: "content-width",
        desktop: "content-width",
      }}
    />
  </React.Fragment>
)

const InfoCards: React.FC = () => {
  return (
    <section
      css={css`
        position: relative;
      `}
    >
      <h2
        css={css`
          ${mixins.invisible}
        `}
      >
        Our services
      </h2>
      <ul>
        <Card>
          <Title
            css={css`
              min-width: 45%;
            `}
          >
            Get a mortgage or switch your deal{" "}
            <span
              css={css`
                ${typographyStyles.statement4}
              `}
            >
              (free)
            </span>
          </Title>
          <div
            css={css`
              ${breakpoints.tablet`
                min-width: 50%;
                width: 50%;
              `}
            `}
          >
            {brokerageCardBody}
          </div>
        </Card>

        <Card alignedRight={true}>
          <div
            css={css`
              display: flex;
              flex-direction: column;

              ${breakpoints.desktop`
                min-width: 65%;
              `}
            `}
          >
            <Title
              css={css`
                margin-bottom: 0;
              `}
            >
              Our complete{" "}
              <span
                css={css`
                  display: table;
                `}
              >
                home-buying service
              </span>
            </Title>
            <p
              css={css`
                ${typographyStyles.statement4};
                margin-bottom: ${vertical.xs};
              `}
            >
              (from £2,000)
            </p>
            <div
              css={css`
                display: none;

                ${breakpoints.desktop`
                  display: block;
                  margin-top: ${vertical.s};
                `}
              `}
            >
              <PlusLogo />
            </div>
          </div>
          <div
            css={css`
              ${breakpoints.tablet`
                min-width: 50%;
                width: 50%;
              `}

              ${breakpoints.desktop`
                min-width: 35%;
              `}
            `}
          >
            {plusCardBody}
          </div>
        </Card>
      </ul>
    </section>
  )
}

export default InfoCards
